import React, { useState, useEffect, useContext } from 'react';
import {DropzoneAreaBase} from 'material-ui-dropzone'
import FileViewer from './FileViewer'
import AppContext from '../context/app'

import riskSurveyService from '../services/riskSurvey';

export default function File(props) {
  const context = useContext(AppContext)
  const [assetNames, setAssetNames] = useState([])
  const [files, setFiles] = useState([])
  const [fileViewerOpen, setFileViewerOpen] = useState(false)
  const [fileViewerAssetIndex, setFileViewerAssetIndex ] = useState()

  const bindAssets = async () => {
    let assetNames = await new riskSurveyService().getAssetNames(+props.riskSurveyId, +props.moduleId)
    const imageExt = ['.png','.gif','.jpg','.jpeg']
    let endsWithAny = (suffixes, string) => {
      return suffixes.some(function (suffix) {
          return string.toLowerCase().endsWith(suffix);
      });
    }
    assetNames.forEach( (a) => {
      a.isImage = endsWithAny(imageExt, a.fileName)
    })
    setAssetNames(assetNames)
  }

  useEffect( async () => {
    bindAssets()
  },[])

  useEffect( async () => {
    bindAssets()
  },[props.moduleId])

  const handleUploadFile = async (files) => {
    if (!files.length)
      return

    try {
      async function uploadFiles() {
        for (const file of files) {
          let data = new FormData()
          data.append('file', file.file)
          await new riskSurveyService().uploadFile(+props.riskSurveyId, +props.moduleId, data)
        }
      }

      await uploadFiles.bind(this)()

      //reload the images
      bindAssets()
      setFiles([])
      context.toastSuccess('File uploaded')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be uploaded')
      setFiles([])
    }

  }

 const handleDeleteAsset = async (assetId) => {
    try {
      await new riskSurveyService().deleteAsset(+assetId )
      bindAssets()
    }
    catch(err){
      console.log(err)
      context.toastError('Deleting file')
    }

  }

  return (
    <>
      <FileViewer open={fileViewerOpen} onClose={e => setFileViewerOpen(false)} files={assetNames} index={fileViewerAssetIndex} riskSurveyId={props.riskSurveyId} moduleId={props.moduleId} ></FileViewer>
      <div id='riskSurveyModule'>
        <div className="flex-row">
          { assetNames.map( (fileName, index) => {
            return(
              <div className="flex-col uploaded-file-container" key={index}>
                { fileName.isImage ?
                  <img  
                    src={`/api/riskSurvey/asset/getAsset?assetId=${fileName.riskSurveyModuleAssetId}`}
                    className="uploaded-file"
                    data-filename={fileName.fileName}
                    alt={ fileName.notes || fileName.fileName  }
                    title={fileName.notes || fileName.fileName }
                    onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                  />
                :
                  <span 
                    className="fa fa-file uploaded-file uploaded-file-file"
                    data-fileName={fileName.fileName}
                    alt={ fileName.notes || fileName.fileName  }
                    title={fileName.notes || fileName.fileName }
                    onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                  />
                }

                <span>
                    <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteAsset(fileName.riskSurveyModuleAssetId) }}></i>
                </span>
                <div className="uploaded-file-caption">
                  {fileName.notes ||  fileName.fileName.substring(0,9)}
                </div>
              </div>
            )
            }) 
            }

            <div className="flex-row section">
              <div className="dropzonearea flex-col">                  
              <DropzoneAreaBase
                  filesLimit={30}
                  maxFileSize={30000000}
                  onAdd={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadFile(newFiles)}}
                  fileObjects={files}
                />
              </div>
            </div>
      </div>
    </div>
    </>
  )
}