import React from 'react';
import AppContext from '../context/app'
import {TextField } from '@mui/material'


class MinSecAnswer extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
  }

  componentWillMount () {
    this.props.question.answers = this.props.question.answers || []
  }

  handleAnswerChange (event) {
    let question = this.props.question
    let answers = question.answers  
    let questionAnswer = { questionId: question.questionId,  answer: `${event.target.value} ${event.target.name}`}
    
    let answerIndex = answers.findIndex( a => a.answer.endsWith(event.target.name))
    if (answerIndex === -1)
        answers.push(questionAnswer)
    else
        answers[answerIndex] = questionAnswer
    question.answers = answers

    //push state up to module level
    this.props.handleAnswerChange(question)
  }

  render () {
    return (
        <div>
            <div className="question-text">
                Q{this.props.question.index+1}: {this.props.question.text}
            </div>
            <div>
              <TextField className="minsec-input" type='number' maxLength="30" name='Minutes' value={this.props.question.answers.find( a => a.answer.endsWith('Minutes')) ? this.props.question.answers.find( a => a.answer.endsWith('Minutes')).answer.replace(' Minutes','') : '' } label="Minutes" required disabled={this.props.disabled} onChange={this.handleAnswerChange} />
              <TextField className="minsec-input" type='number' maxLength="30" name='Seconds' value={this.props.question.answers.find( a => a.answer.endsWith('Seconds')) ? this.props.question.answers.find( a => a.answer.endsWith('Seconds')).answer.replace(' Seconds','') : '' } label="Seconds" required disabled={this.props.disabled} onChange={this.handleAnswerChange} />
            </div>
        </div>
    )
  }

}

export default MinSecAnswer
