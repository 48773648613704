import React from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import riskSurveyService from '../../services/riskSurvey';
import riskSurveyTypeConfig from '../../config/surveyTypes.json'
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import AppContext from '../../context/app'
import { Paper } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { TextField, Button, Icon } from '@mui/material';



class Detail extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { config: {}, openShareDialog: false }
    this.history = createBrowserHistory();
    this.riskSurveyService = new riskSurveyService();
    this.startRiskSurveyClick = this.startRiskSurveyClick.bind(this)    
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handlePublishToTeam = this.handlePublishToTeam.bind(this)
    this.handleNotify = this.handleNotify.bind(this)
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClickOpenShareDialog = this.handleClickOpenShareDialog.bind(this)
    this.handleShareSurvey = this.handleShareSurvey.bind(this)
    this.handleCloseOpenShareDialog = this.handleCloseOpenShareDialog.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.downloadExcel = this.downloadExcel.bind(this);
    this.download = this.download.bind(this)
    this.downloadFinalReport = this.downloadFinalReport.bind(this)
  }

  async componentDidMount () {
    window.scroll(0,0)
    this.context.updateRiskSurveyId(this.props.match.params.riskSurveyId)

    //reset the nav
    this.context.updateActiveModule(null);

    let riskSurvey = await this.riskSurveyService.get(this.props.match.params.riskSurveyId)
    if (riskSurvey && riskSurvey.error)
      return
    
    this.context.updateRiskSurvey(riskSurvey);
    this.setState( { riskSurvey } );

    let config = riskSurveyTypeConfig[riskSurvey.riskSurveyTypeId]
    this.setState( { config } );
  }

  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }

  startRiskSurveyClick () {
    this.context.updateActiveModule(this.context.riskSurvey.modules[0]);
  }

   //close the risk Survey
   async handleClose(event){
     try{
      event.preventDefault();
      let riskSurveyId = this.props.match.params.riskSurveyId;
      let riskSurvey = { riskSurveyId, isOpen: false };

      await this.riskSurveyService.close(riskSurvey)
      this.props.history.push({pathname: '/home' });
     }
     catch(err){
      console.log(err)
      this.context.toastError('Closing Survey')
    }
  }

  async handleDelete(event){
    try {
      event.preventDefault();
      let riskSurveyId = this.props.match.params.riskSurveyId;
      let riskSurvey = { riskSurveyId };

      await this.riskSurveyService.delete(riskSurvey)
      this.props.history.push({pathname: '/home' });
    }
    catch(err){
     console.log(err)
     this.context.toastError('Deleting Survey')
   }
  }

  downloadExcel(event){
    this.riskSurveyService.downloadExcel(this.props.match.params.riskSurveyId)
  }

  download(event){
    try{
      event.preventDefault();
      let riskSurveyId = this.props.match.params.riskSurveyId;
      this.riskSurveyService.download(riskSurveyId)
    }
    catch(err){
      console.log(err)
      this.context.toastError('Downloading Survey')
   }
 }

 
 downloadFinalReport(event){
  try{
    event.preventDefault();
    let riskSurveyId = this.props.match.params.riskSurveyId;
    this.riskSurveyService.downloadFinalReport(riskSurveyId)
  }
  catch(err){
    console.log(err)
    this.context.toastError('Downloading Survey')
 }
}

  handleClickOpenShareDialog = () => {
    this.setState({
      openShareDialog: true,
    });
  };

  handleCloseOpenShareDialog = () => {
    this.setState({ openShareDialog: false });
  };

  async handleShareSurvey(e){
    e.preventDefault();

      try {
          await this.riskSurveyService.email(+this.props.match.params.riskSurveyId, this.state.shareEmail)
          this.setState({ shareEmail: null, openShareDialog: false });
          this.context.toastSuccess('Survey shared')
      }
      catch(err){
          console.log(err)
          this.context.toastError('Survey could not be shared')
      }

}

async handlePublishToTeam(event){
  try {
    event.preventDefault();
    let riskSurveyId = this.props.match.params.riskSurveyId;
    await this.riskSurveyService.publish(riskSurveyId)
    this.props.history.push({pathname: '/home' });
  }
  catch(err){
    console.log(err)
    this.context.toastError('Publishing Survey')
  }
}


async handleNotify() {
  try {
    let riskSurveyId = this.props.match.params.riskSurveyId;
    await this.riskSurveyService.notify(riskSurveyId)
    this.context.toastSuccess('Notificaiton Sent')
  }
  catch(err){
    console.log(err)
    this.context.toastError('Notificaiton Error')
  }
}

  //reopen the risk Survey
  async handleOpen(event){
    try {
      event.preventDefault();
      let riskSurveyId = this.props.match.params.riskSurveyId;
      let riskSurvey = { riskSurveyId, isOpen: true };

      await this.riskSurveyService.update(riskSurvey)
      this.props.history.push({pathname: '/home' });
    }
    catch(err){
      console.log(err)
      this.context.toastError('Reopening Survey')
    }
  }

  render () {
    return (
      <div id='riskSurveyDetail'>
        <Dialog open={this.state.openShareDialog} onClose={this.handleCloseOpenShareDialog} aria-labelledby="simple-dialog-title">
            <DialogTitle id="dialog-title">Share Survey with:</DialogTitle>
            <div className="share-dialog">
                <form onSubmit={this.handleShareSurvey}>
                    <div className="share-dialog-email">
                      <TextField className="shareEmail" type="email" maxLength="320" name='shareEmail' value={this.state.shareEmail || ''} label="Email" required onChange={this.handleChange} />
                    </div>

                    <div className="share-button-container">
                      <Button type="submit" className="share" variant="outlined" disabled={!this.state.shareEmail }><Icon className="fa fa-paper-plane"/>Share</Button>
                    </div>
                </form>
            </div>
        </Dialog>

        { this.state.riskSurvey ?
          <div>
             <div className="flex-row header">
                <div className="flex-col">
                  <h1><span dangerouslySetInnerHTML={ { __html: this.state.riskSurvey.name} }></span></h1>
                </div>
                <div className="flex-col">
                  <div className="flex-row actions">
                    { this.context.riskSurvey.riskSurveyTypeSystem === 'riskSurvey' ?
                      <span className="action flex-col">
                        <Link to={`/riskSurvey/module/${this.props.match.params.riskSurveyId}/1`} onClick={this.startRiskSurveyClick}><Button variant="outlined"><Icon className="fa fa-arrow-right"/>Start</Button></Link>
                      </span>
                    : null }

                    { this.state.config?.analyzable ?
                        <span className="action flex-col">
                          <Link to={`/riskSurvey/report/${this.props.match.params.riskSurveyId}`}><Button variant="outlined"><Icon className="fa fa-list"/>Analyze</Button></Link>
                        </span>
                    : null }

                    <span className="action flex-col">
                      <Button variant="outlined"  onClick={this.handleClickOpenShareDialog}><Icon className="fa fa-paper-plane"/>Share</Button>
                    </span>

                    { !this.state.riskSurvey.isPublishToTeam ?
                      <span className="action flex-col">
                        <Button variant="outlined"  onClick={this.handlePublishToTeam}><Icon className="fa fa-share-alt"/>Publish</Button>
                      </span>
                    : 
                      <span className="action flex-col">
                        <Button variant="outlined"  onClick={this.handleNotify}><Icon className="fa fa-bell"/>Notify Team</Button>
                      </span>
                    }
        
                    <span className="action flex-col">
                      { this.state.riskSurvey.isOpen ?
                        <Button variant="outlined"  onClick={this.handleClose}><Icon className="fa fa-archive"/>Archive</Button>                 
                      :   
                        <Button variant="outlined"  onClick={this.handleOpen}><Icon className="fa fa-box-open"/>Re-Open</Button>                 
                      }
                    </span>

                    <span className="action flex-col">
                      <Menu>
                        <MenuItem onClick={this.download} fontAwesomeIcon="fa fa-download" title="Download" ></MenuItem> 

                        {this.state.riskSurvey.riskSurveyTypeId === 2 ?
                          <MenuItem onClick={this.downloadFinalReport} fontAwesomeIcon="fa fa-download" title="Final Report" ></MenuItem> 
                        : null }

                        { this.state.config?.excel ?
                          <MenuItem onClick={this.downloadExcel} fontAwesomeIcon="fa fa-table" title="Excel" ></MenuItem> 
                        : null }
          
                        <Link to={`/riskSurvey/edit/${this.props.match.params.riskSurveyId}`}><MenuItem fontAwesomeIcon="fa fa-edit" title="Edit" ></MenuItem></Link>                          

                        <Link to={`/riskSurvey/copy/${this.props.match.params.riskSurveyId}`}><MenuItem fontAwesomeIcon="fa fa-copy" title="Copy" ></MenuItem></Link>                          

                        <MenuItem fontAwesomeIcon="fa fa-trash" title="Delete" onClick={ (e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDelete(e) }} ></MenuItem>
                      </Menu>
                    </span>
                  </div>
                </div>
              </div>

              <Paper className="section" elevation={1}>
              <div className="flex-row">
                <span className="flex-col">
                  Created By: {this.state.riskSurvey.createdByFirstName} {this.state.riskSurvey.createdByLastName}
                </span>
              </div>
              </Paper>

              { this.state.riskSurvey.synthasisSummary ?
                <Paper className="section" elevation={1}>
                  <div className='section'>
                      <span className="ai-generated-label">AI Generated</span> {this.state.riskSurvey.synthasisSummary}
                      <div className="ai-generated-warning">
                          Summary generated by AI, please review all information.
                      </div>
                  </div>
                </Paper>
                :  
                <div>
                  { this.state.config?.purpose || this.state.config?.expectation ? 
                  <Paper>
                    { this.state.config?.purpose ? 
                      <div>
                        <p><strong>WHAT IS THE PURPOSE OF A SURVEY</strong></p>
                        <p>
                          {this.state.config.purpose}
                        </p>
                      </div>
                    : null }

                    { this.state.config?.expectation ?
                      <div>
                        <p><strong>WHAT IS TO BE EXPECTED FROM A SURVEY</strong></p>
                        <p>
                          <span dangerouslySetInnerHTML={ { __html: this.state.config.expectation } }></span>
                        </p>
                      </div>
                    : null }
                  </Paper>
              : null }
                </div>
              }

              

            
          </div>
         : null }
      </div>
    )
  }

}


export default Detail
