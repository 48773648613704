import React from 'react'
import AppContext from '../context/app'


class MenuItem extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { }
  }


  render () {
    return (
      <div onClick={this.props.onClick} className="menu-item">
         <i className={this.props.fontAwesomeIcon}></i> {this.props.title}
      </div>
    )
  }
}

export default MenuItem;
