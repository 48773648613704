import React, { useState, useReducer, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import riskSurveyService from '../../services/riskSurvey';
import institutionService from '../../services/institution';
import userService from '../../services/user'
import AppContext from '../../context/app'
import { Paper, Button, Icon, Select, MenuItem, TextField, InputLabel, FormControl} from '@mui/material';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

export default function Create(props) {
  const context = useContext(AppContext)
  const { riskSurveyId, copyFromRiskSurveyId } = useParams();

  const [riskSurveyTypes, setRiskSurveyTypes] = useState([])
  const [riskSurveyType, setRiskSurveyType] = useState()

  const [institutions, setInstitutions] = useState([])
  const [users, setUsers] = useState([])

  const [riskSurvey, setRiskSurvey] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { users: [{ userId: context.user.userId}], inspectionDate: moment.utc().format('YYYY-MM-DD'), dateOfBirth: moment.utc().format('YYYY-MM-DD') }
  );

  useEffect( async () => {
    window.scroll(0,0)
    context.updateRiskSurvey(null);
    context.updateRiskSurveyId(null);

    try {
      //create mode
      if (!riskSurveyId){
        const institutions = await new institutionService().getManaged() 
        setInstitutions(institutions)
        if (institutions.length === 1)
          riskSurvey.institutionId = institutions[0].institutionId
        
        //on copy
        if (copyFromRiskSurveyId){
          let riskSurvey = await new riskSurveyService().get(copyFromRiskSurveyId)
          riskSurvey =  (({ riskSurveyId, riskSurveyTypeId, institutionId, name, address, phone, district, buildingCode, inspectionDates, users, gender, dateOfBirth, externalCaseNumber, config}) => ({ riskSurveyId, riskSurveyTypeId, institutionId, name, address, phone, district, buildingCode, inspectionDates, users, gender, dateOfBirth, externalCaseNumber, config }))(riskSurvey);        
          
        }  
      }
      else {
        let riskSurvey = await new riskSurveyService().get(riskSurveyId) 
        riskSurvey =  (({ riskSurveyId, riskSurveyTypeId, institutionId, name, address, phone, district, buildingCode, inspectionDates, users, gender, dateOfBirth, externalCaseNumber, config, data}) => ({ riskSurveyId, riskSurveyTypeId, institutionId, name, address, phone, district, buildingCode, inspectionDates, users, gender, dateOfBirth, externalCaseNumber, config, data }))(riskSurvey);
      }
      setRiskSurvey(riskSurvey)

    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  
  useEffect( async () => {
    try {
      if (riskSurvey.institutionId){
        let riskSurveyTypes = await new institutionService().getRiskSurveyTypes(riskSurvey.institutionId)
        setRiskSurveyTypes(riskSurveyTypes)
        //if create pre-select riskTypes
        if (!riskSurveyId && riskSurveyTypes.length === 1)
          riskSurvey.riskSurveyTypeId = riskSurveyTypes[0].riskSurveyTypeId
        let institutionUsers = await new userService().getByInstitution(riskSurvey.institutionId)
        institutionUsers.forEach( u => u.display = u.firstName ? `${u.firstName} ${u.lastName}` : u.email )
        setUsers(institutionUsers)
      }
    }
    catch (err) {
      console.log(err)
      context.toastError('changing institution')
    } 
    setRiskSurvey(riskSurvey)

  },[riskSurvey.institutionId])

  useEffect(() => {
    if (riskSurveyTypes.length){
      if (riskSurvey.riskSurveyTypeId) {
        let riskSurveyType = riskSurveyTypes.find( rt => rt.riskSurveyTypeId === riskSurvey.riskSurveyTypeId)
        setRiskSurveyType(riskSurveyType)
      }
    }

  },[riskSurvey.riskSurveyTypeId, riskSurveyTypes])


  const handleCreate = async (event) => {
    event.preventDefault();
    try {
      if (!riskSurveyId){
        let savedRiskSurvey = await new riskSurveyService().create(riskSurvey)
        props.history.push({pathname: `/riskSurvey/detail/${savedRiskSurvey.riskSurveyId}` });
      }
      else{
        await new riskSurveyService().update(riskSurvey)
        props.history.push({pathname: `/riskSurvey/detail/${riskSurveyId}` });
      }
      context.toastSuccess('Survey saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Saving Survey')
    }
  }

  return (
    <div id='riskSurveyCreate'>
        <div>
          <form onSubmit={handleCreate}>
            <Paper className="paper">
              { institutions.length > 1 ?
                <div>
                  <div>
                    <FormControl required className="section">
                      <InputLabel>Institution</InputLabel>
                      <Select label="Institution" required name="institutionId" value={riskSurvey.institutionId || ''} onChange={(e) => { riskSurvey.institutionId = e.target.value; setRiskSurvey(riskSurvey)  }}  >
                            { institutions.map( (managedInstitution, index) => {
                              return (
                                    <MenuItem key={index} value={managedInstitution.institutionId}><span dangerouslySetInnerHTML={ { __html: managedInstitution.name } }></span></MenuItem>
                                )
                              })
                            }
                      </Select>
                    </FormControl>
                  </div>
                </div>
              : null }

              { !copyFromRiskSurveyId && riskSurveyTypes.length > 1 ?             
                <div>
                  <FormControl required className="section">
                    <TextField select label="Type" required name="riskSurveyTypeId" value={riskSurvey.riskSurveyTypeId || ''} onChange={(e) => { riskSurvey.riskSurveyTypeId = e.target.value; setRiskSurvey(riskSurvey) }} >
                          { riskSurveyTypes.map( (riskSurveyType, index) => {
                            return (
                                  <MenuItem key={index} value={riskSurveyType.riskSurveyTypeId}>{riskSurveyType.name}</MenuItem>
                              )
                            })
                          }
                    </TextField>
                  </FormControl>
                </div>
              :
                null
              }

              { riskSurvey.riskSurveyTypeId ?
              <section>
                <div>
                    <div className="section">
                      <TextField label={riskSurveyType?.config?.subjectLabel} name="name" type="text" maxLength="80" value={riskSurvey.name || ''} required onChange={(e) => { riskSurvey.name = e.target.value; setRiskSurvey(riskSurvey)}}/>
                    </div>
                </div>

                  <div>
                    <div className="section">
                      <TextField label="Address" name="address" type="text" maxLength="45" value={riskSurvey.address || ''} required onChange={(e) => { riskSurvey.address = e.target.value; setRiskSurvey(riskSurvey)}}/>
                    </div>
                  </div>

                  <div>
                    <div className="section">
                      <TextField label="Phone" name="phone" type="text" maxLength="20" value={riskSurvey.phone || ''} onChange={(e) => { riskSurvey.phone = e.target.value; setRiskSurvey(riskSurvey)}}/>
                    </div>
                  </div>

                  {riskSurveyType?.config?.extraSubjectOfConcernData ? 
                  <section>
                      <div className="section">
                        <TextField select label="Identification" value={riskSurvey.gender || ''} onChange={(e) => { riskSurvey.gender = e.target.value; setRiskSurvey(riskSurvey)}}>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Neutral">Neutral</MenuItem>
                        </TextField>
                      </div>

                      <div className="section">
                        <TextField label="Date Of Birth"  type="date" onChange={(e) => { riskSurvey.dateOfBirth = e.target.value; setRiskSurvey(riskSurvey)}} value={riskSurvey.dateOfBirth}/>
                      </div>
                  </section>
                  : null
                  }

                  {riskSurveyType?.config?.contact ? 
                    <div className="section" >
                      <h2>Organizational Representitive</h2>
                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField label="Name" type="text" maxLength="200" value={riskSurvey.data?.contactName || ''} onChange={(e) => { riskSurvey.data = riskSurvey.data ?? {}; riskSurvey.data.contactName = e.target.value; setRiskSurvey(riskSurvey)}}/>
                          </div>

                          <div className="left-space flex-col">
                            <TextField label="Title" type="text" maxLength="30" value={riskSurvey.data?.contactTitle || ''} onChange={(e) => { riskSurvey.data = riskSurvey.data ?? {}; riskSurvey.data.contactTitle = e.target.value; setRiskSurvey(riskSurvey)}}/>
                          </div>
                        </div>
                    </div>
                  : null
                  }

                  { riskSurvey.riskSurveyTypeId === 2 ?
                  <div>                    
                    <div>
                      <div className="section">
                        <TextField label="District" name="district" type="text" maxLength="45" value={riskSurvey.district || ''} onChange={(e) => { riskSurvey.district = e.target.value; setRiskSurvey(riskSurvey)}}/>
                      </div>
                    </div>

                    <div>
                      <div className="section">
                        <TextField label="BEDS Code" name="buildingCode" type="text" maxLength="45" value={riskSurvey.buildingCode || ''} onChange={(e) => { riskSurvey.buildingCode = e.target.value; setRiskSurvey(riskSurvey)}}/>
                      </div>
                    </div>
                  </div>
                  : 
                    null          
                  }
            </section>
            : null }
          </Paper>

          <Paper className="paper">
                { riskSurvey.riskSurveyTypeId !== 2 ?
                <div className="section">
                    <TextField label="Case Code" type="text" maxLength="45" value={riskSurvey.externalCaseNumber || ''} onChange={(e) => { riskSurvey.externalCaseNumber = e.target.value; setRiskSurvey(riskSurvey)}}/>
                </div>
                : null }

                <div>
                    <div className="section">
                      <TextField label={riskSurveyType?.config.dateLabel} name="inspectionDate" type="date" onChange={(e) => { riskSurvey.inspectionDate = e.target.value; setRiskSurvey(riskSurvey)}} value={riskSurvey.inspectionDate}/>
                    </div>
                </div>

                { users.length ?
                    <div>
                      { riskSurvey.users.map( (user, index) => {
                        return(
                          <div key={index} className="section flex-row">
                            <div className="flex-col">
                              <TextField label={riskSurveyType?.config.userLabel} select value={user.userId || ''} required onChange={(e) => { user.userId = e.target.value; setRiskSurvey(riskSurvey)}}>           
                              { users.map( (user, index) => {
                                  return(
                                    <MenuItem key={index} value={user.userId}>
                                      {user.display}
                                    </MenuItem>
                                  )
                                }) 
                              }
                              </TextField>
                            </div>
                            <div className="flex-col">
                              { index ?
                                <span className="flex-col edit-close "><i className="fa fa-times" onClick={ () => { riskSurvey.users.splice(index, 1); setRiskSurvey(riskSurvey)} }></i></span>
                              : null }
                            </div>
                          </div>
                        )
                      })}
                      <div className="add-another">
                        <span className='addAnother' onClick={() => {riskSurvey.users.push({}); setRiskSurvey(riskSurvey)}}><i className="fa fa-plus"> Add Another</i></span>
                      </div>
                    </div>                   
                  : null }
            </Paper>

            <div className="section">
              <span >
                <Button type="submit" variant="outlined"><Icon className="fa fa-save"/>Save</Button>
              </span>

              <span className="action other-btn">              
                <Link to={`/home`}><span className='subtle-link'><i className="fa fa-arrow-left"> Back</i></span>  </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
  )
}