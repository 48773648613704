import React from 'react';
import moment from 'moment'
import { Paper } from '@mui/material';

import riskSurveyService from '../../services/riskSurvey';
import AppContext from '../../context/app'

class Report extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { };
    this.riskSurveyService = new riskSurveyService();
    this.printPage = this.printPage.bind(this); 
    this.downloadPdf = this.downloadPdf.bind(this);
    this.downloadOneOfPdf = this.downloadOneOfPdf.bind(this); 
  }

  componentDidMount () {
    window.scroll(0,0)

    this.riskSurveyService.get(this.props.match.params.riskSurveyId)
    .then( (riskSurvey) => {
      this.setState({riskSurvey});
    });

    this.riskSurveyService.getAssetNames(+this.props.match.params.riskSurveyId, 12)
    .then( (assetNames) => {
      const imageExt = ['.png','.gif','.jpg','.jpeg']
      let endsWithAny = (suffixes, string) => {
        return suffixes.some(function (suffix) {
            return string.toLowerCase().endsWith(suffix);
        });
      }
      let imageNames = assetNames.filter( (asset) => { return endsWithAny(imageExt, asset.fileName)  } )
      this.setState({ imageNames })
    })
  }

  render () {
    return (
      <div id='riskSurveyReport'>
                { this.state.riskSurvey && this.state.imageNames ?
                <div>
                  { this.state.riskSurvey ? 
                      <div>
                          <span className="print">
                            <span onClick={this.printPage}><i className="fa fa-print"></i> Print</span>
                          </span>
                          
                          { this.state.riskSurvey.pdfs.length === 1 ? 
                            <span className="pdf">
                              <span onClick={this.downloadPdf}><i className="fa fa-file"></i> Pdf</span>
                            </span>
                          : null }

                          { this.state.riskSurvey.pdfs.length > 1 ? 
                            <span className="pdfs">
                              <select id="risk-survey-pdfs" name="pdfs" onChange={this.downloadOneOfPdf} >
                              <option value="">PDF</option>
                              { this.state.riskSurvey.pdfs.map( (pdf, index) => {
                                return (
                                  <option key={index} value={pdf.fileName}>{moment.utc(pdf.timeStamp).local().format('MM/DD/YYYY')}</option>
                                )
                              })}
                              </select>

                            </span>
                          : null }


                          <div id="report">
                            <h1 className="sub-title"><span dangerouslySetInnerHTML={ { __html: this.state.riskSurvey.name} }></span></h1>
                            
                            <div>
                              <span dangerouslySetInnerHTML={ { __html: this.state.riskSurvey.institutionName} }></span>
                            </div>

                            <div>
                              {this.state.riskSurvey.address}
                            </div>

                            <div>
                              {this.state.riskSurvey.phone}
                            </div>

                            { this.state.riskSurvey.modules.map( (module, index) => {
                                let riskBarWidth = '0%'; 
                                let moduleRating = null
                                
                                if ( this.state.riskSurvey.config.score && !module.notesOnly) {
                                  moduleRating = module.moduleRatings;
                                  riskBarWidth = moduleRating.riskBarWidth
                                }

                                let note = module.notes

                                let imageNames = this.state.imageNames.filter( (imageName) => { return imageName.moduleId === module.moduleId})

                                return(
                                  <div className="interview" key={index}>
                                     <Paper>
                                        <h2>{module.name}</h2>
                                          
                                        { ( 
                                            (!module.notesOnly && this.state.riskSurvey.riskSurveyTypeId === 2 ) ||
                                            (!module.notesOnly && module.questions.filter( q => ['YesNo','text','number','radio'].indexOf(q.questionType) > -1 ).some( q => q.answers.length )) ||
                                            (imageNames.length) ||
                                            (module.notesOnly && note) 
                                          ) ?
                                          <div>
                                            { module.questions.length ?
                                              <div>
                                                {this.state.riskSurvey.config.score && !module.noScore ?
                                                  <div className="risk-survey-bar-holder">
                                                      <div className="risk-survey-bar" style={{width : riskBarWidth, 'backgroundColor': moduleRating.ratingCategory }} >
                                                        <span className="rating-label"><strong>Risk Level:</strong> {moduleRating.ratingLabel}</span>
                                                      </div>
                                                  </div>
                                                : null }
                                              
                                                { module.questions.filter( q => this.state.riskSurvey.riskSurveyTypeId === 2 || q.answers.length).map( (question, index) => {
                                                    if (['YesNo','text','number','radio'].indexOf(question.questionType) > -1) {
                                                      return(
                                                        <div className="question" key={index}>
                                                          <div>Q{index+1}: <span dangerouslySetInnerHTML={ { __html: question.text } }></span></div>
                                                          { question.answers.map( (answer, index) => {
                                                              return (
                                                                <div key={index}>
                                                                  <span className="answer" >A: { answer.answer } </span>
                                                                  { answer.notes ?
                                                                      <span>{answer.notes}</span>
                                                                    : null }

                                                                  { answer.dates.length ? 
                                                                  <div>
                                                                    <ul className="dates">
                                                                    { answer.dates.map( (date, index) => {
                                                                      return (
                                                                        <li key={index}>{moment.utc(date.date).format('MM/DD/YYYY')}</li>
                                                                      )
                                                                    })} 
                                                                    </ul>                                         
                                                                  </div>
                                                                  : null }

                                                                </div>
                                                              )
                                                          })}
                                                        </div>
                                                      )
                                                    }

                                                    if (['CheckBox','MinSec'].indexOf(question.questionType) > -1) {
                                                      return(
                                                        <div className="question" key={index}>
                                                          <div>Q{index+1}: {question.text}</div>
                                                          { question.answers.length ? 
                                                            <div>
                                                            { question.answers.map( (answer, index) => {
                                                              return (
                                                                <div key={index}>
                                                                  <span className="answer">{answer.answer } </span>
                                                                  { answer.notes ?
                                                                    <span>notes</span>
                                                                  : null }
                                                                </div>
                                                              )})
                                                            }
                                                            </div>
                                                          :
                                                            <div className="answer">
                                                              None
                                                            </div>
                                                          }
                                                        </div>
                                                      )
                                                    }

                                                    return (<div></div>)
                                                  })
                                                }
                                              </div>
                                            : null }

                                            { note && note.recommendation && note.recommendation.length ?  
                                              <div className="module-recommendaiton" >
                                                <div dangerouslySetInnerHTML={ { __html: note.recommendation.replace(/\n/g,'<br>') } }></div>
                                              </div>
                                            : null }

                                            
                                            { imageNames.length ? 
                                              <div>
                                                 { imageNames.map( (imageName, index) => {
                                                  return(
                                                    <div className="image" key={index}>
                                                      <div >
                                                      <img
                                                        className="uploaded-image"
                                                        src={`/api/riskSurvey/asset/getAsset?riskSurveyId=${this.props.match.params.riskSurveyId}&moduleId=${module.moduleId}&fileName=${imageName.fileName}`}
                                                        alt={ imageName.notes || 'uploaded' }
                                                      />
                                                      </div>
                                                      <div>
                                                        {imageName.notes}
                                                      </div>
                                                    </div>
                                                  )
                                                  }) 
                                                }
                                              </div>
                                            : null}

                                          </div>
                                        : 
                                          <div>
                                            { (module.notesOnly && !note) ?
                                              <div>No Notes</div>
                                            : 
                                              <div>No Data Entered</div> 
                                            }
                                          </div> 
                                        }
                                    </Paper>
                                  </div>                                
                                ) 
                              })
                            }

                            { this.state.riskSurvey.config.showCommendations && this.state.riskSurvey.commendations.length ?
                              <div>
                                <h2>Commendations</h2>
                                <ul className="recommendation">
                                    { this.state.riskSurvey.commendations.map( (commendation, index) => {
                                        return(
                                          <li key={index}>
                                            <span>{commendation.text}</span>
                                            { commendation.answers && commendation.answers[0] && commendation.answers[0].notes && commendation.answers[0].notes.length ? 
                                              <span> - {commendation.answers[0].notes}</span>
                                            : null
                                            }
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                              </div>
                            : null }

                            { this.state.riskSurvey.config.showRecommendations && this.state.riskSurvey.recommendations.length ?
                              <div>
                                <h2>Recommendations</h2>
                                <ul className="recommendation">
                                  { this.state.riskSurvey.recommendations.map( (recommendation, index) => {
                                      if (!recommendation.answers[0] || !recommendation.answers[0].notes || !recommendation.answers[0].notes.length )
                                        return null

                                      return(
                                        <li key={index}>
                                          <span>{recommendation.answers[0].notes}</span>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            : null }
                          </div>
                  </div>
                  :
                      null
                  }
                  </div>
                : null }

                <footer className="disclaimer">Disclaimer: The recommendations contained in this report are written as a guide to enhance safety
and security. The recommendations are not all-inclusive. Furthermore, changes made to policies,
protocols, and physical environment based on the recommendations in this report will not guarantee 100% safety. Users
are free to accept or reject any or all the recommendations outlined in this report.</footer>
            </div>
    )
  }


  //set to state
  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }

  printPage(event){
    window.print();
  }

  downloadExcel(event){
    this.riskSurveyService.downloadExcel(this.props.match.params.riskSurveyId)
  }

  download(event){
    try{
      event.preventDefault();
      let riskSurveyId = this.props.match.params.riskSurveyId;
      this.riskSurveyService.download(riskSurveyId)
    }
    catch(err){
      console.log(err)
      this.context.toastError('Downloading Survey')
   }
 }

 
 downloadFinalReport(event){
  try{
    event.preventDefault();
    let riskSurveyId = this.props.match.params.riskSurveyId;
    this.riskSurveyService.downloadFinalReport(riskSurveyId)
  }
  catch(err){
    console.log(err)
    this.context.toastError('Downloading Survey')
 }
}

  downloadPdf(event){
    this.riskSurveyService.downloadPdf(this.props.match.params.riskSurveyId, this.state.riskSurvey.pdfs[0].fileName)
  }

  downloadOneOfPdf(event){
    if (!event.target.value)
      return

    this.riskSurveyService.downloadPdf(this.props.match.params.riskSurveyId, event.target.value)
  }


}


export default Report
