import 'es6-promise'

export default class userService {
  get() {
    return fetch('/api/user/get', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async getByInstitution(institutionId) {
    return fetch(`/api/user/getByInstitution?institutionId=${institutionId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  registerEnforcementOfficial(userEnforcementOfficialInfo){
    return fetch('/api/user/registerEnforcementOfficial', { 
      method: 'POST', 
      body: JSON.stringify(userEnforcementOfficialInfo), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

}