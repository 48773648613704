export default class institutionService {
  async getRiskSurveyTypes(institutionId) {
    return fetch(`/api/institution/getRiskSurveyTypes?institutionId=${institutionId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async getManaged() {
    return fetch('/api/institution/getManaged', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}