import React from 'react';
import { createBrowserHistory } from 'history';
import moment from 'moment'
import riskSurveyService from '../../services/riskSurvey'
import AppContext from '../../context/app'
import { Paper, TextField, Button, Icon, Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ViolationEntry from '../../components/ViolationEntry';


class Module extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { questions: [], openViolations: [], resolvedViolations: [] };
    this.riskSurveyService = new riskSurveyService();
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleToggleEditMode = this.handleToggleEditMode.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleResolved = this.handleResolved.bind(this)
    this.toggleReference = this.toggleReference.bind(this)
    this.history = createBrowserHistory();
  }

  async getRiskSurvey(riskSurveyId){
    let riskSurvey = await this.riskSurveyService.get(riskSurveyId)
    riskSurvey.modules = riskSurvey.modules.filter( m => !m.fileUpload)
    //put risk survey in state
    this.setState({ riskSurvey })

    let violations = [] 
    riskSurvey.modules.forEach( m => {
        m.questions.filter( q => q.answers.length ).forEach( q => {
            q.answers.forEach( a => {
                violations.push({ moduleName: m.name, moduleNumber: m.moduleNumber, referenceNumber: q.referenceNumber, questionText: q.text, dates: q.date ? [q.date] : [], ...a })        
            })
        })    
    })
    violations.sort( (a,b) => { 
        return moment(a.timeStamp) > moment(b.timeStamp) ? 1 : -1
    })

    let openViolations = violations.filter( v => !v.dates.length )
    let resolvedViolations = violations.filter( v => v.dates.length )
    resolvedViolations.forEach( v => v.resolved = true )

    this.setState({openViolations, resolvedViolations})
   }

   toggleReference () {
       this.setState({ showReference: !this.state.showReference })
   }

  componentDidMount () {
    window.scroll(0,0)
    if (!this.context.riskSurvey) {
        window.location = '/'
        return;
    }

    this.getRiskSurvey(this.context.riskSurvey.riskSurveyId)
  }

  render () {
    return (
      <div id='riskSurveyViolation'>
            { this.state.riskSurvey ?
              <div>
                { this.state.showReference ?
                    <div>
                        <div> 
                            <span onClick={this.toggleReference}><i className="fa fa-minus"></i>Hide Reference</span>
                        </div>
                        <div className="referenceSheet">
                            { this.state.riskSurvey.modules.filter( m => !m.notesOnly).map( (module, index) => {
                                return (
                                    <div key={index}>
                                        <div className="module-heading" >{module.moduleNumber}. {module.name}</div>
                                        <table className="referenceQuestionsTable">
                                            <tbody>
                                                <tr>
                                                    <th>Section</th> 
                                                    <th className="referenceQuestionsTableDescription">Description</th>
                                                    <th>Code Section</th>
                                                </tr>

                                                { module.questions.map( (question, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{question.referenceNumber}</td> 
                                                            <td>{question.text}</td>
                                                            <td>{question.stateCode}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                : 
                    <div>
                        <span onClick={this.toggleReference}><i className="fa fa-plus"></i>Show Reference</span>
                    </div> 
                }

                <h1 className="sub-title">{this.state.riskSurvey.name}</h1>


                { this.state.riskSurvey.isOpen ?
                    <ViolationEntry riskSurvey={this.state.riskSurvey} handleSave={this.handleSave} ></ViolationEntry>
                : null }
              
                { this.state.openViolations.length ?
                <Paper className="section">   
                    <h2>Open</h2>
                    <div className="section">
                        <div className="flex-row">
                            <div className="flex-col">
                                <TextField label="Date Corrected" disabled={!this.state.openViolations.some( v => v.resolve  )} type="date" onChange={(e) => { this.setState({resolvedDate: moment.utc(e.target.value).format('YYYY-MM-DD')}) }} value={moment.utc(this.state.resolvedDate).format('YYYY-MM-DD')}/>
                            </div>
                            <div className="flex-col">
                                <Button className="resolve-button" variant="outlined" disabled={ !this.state.openViolations.some( v => v.resolve  ) || !this.state.resolvedDate } onClick={this.handleResolved}><Icon className="fa fa-save"/>Resolve</Button>
                            </div>
                        </div>
                    </div>
                    { this.state.openViolations.map( (violation, index) => {
                        return (
                            <div key={index}>
                            { violation.editMode ?
                                <div className="edit-violaton">
                                    <div className="flex-row edit-close-container">
                                        <span className="flex-col edit-close "><i className="fa fa-times" onClick={ e => this.handleToggleEditMode(e, violation) }></i></span>
                                    </div>
                                    <ViolationEntry riskSurvey={this.state.riskSurvey} violation={violation} handleSave={this.handleSave} ></ViolationEntry>
                                </div>
                            :
                                <div className="flex-row section violation" key={index}>
                                    <div className="flex-col resolved-col">
                                        <div className="flex-row">
                                            <div className="flex-col">
                                                <Tooltip title="Resolve">
                                                    <Checkbox checked={violation.resolve || false} onClick={(e) => { violation.resolve = !!e.target.checked; let resolvedDate  = moment().format('YYYY-MM-DD');  this.setState({resolvedDate, violations: this.state.violations}) }} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-col violation-col">
                                        {violation.location}: {violation.moduleNumber}{violation.referenceNumber} {violation.moduleName} - <span className="violation-questionText" dangerouslySetInnerHTML={ { __html: violation.questionText } } ></span>  
                                    </div>
                                    <div className="flex-col notes-col">
                                        { violation.notes ?
                                            <span className="note">Notes: {violation.notes}</span>
                                        : null }
                                    </div>
                                    <div className="flex-col action-col">
                                        <div className="flex-row action-row">
                                            <span className="flex-col"><i className="fa fa-edit" onClick={ e => this.handleToggleEditMode(e, violation) }></i></span>
                                            <span className="flex-col"><i className="fa fa-trash" onClick={ e => this.handleDelete(e, violation.riskSurveyModuleQuestionAnswerId) }></i></span>
                                        </div>
                                    </div>
                                </div>
                            }  
                            </div>            
                        )
                    })}
                </Paper> 
                : null }

                { this.state.resolvedViolations.length ?
                <Paper className="section" elevation={1}>   
                    <h2>Resolved</h2>
                    { this.state.resolvedViolations.map( (violation, index) => {
                        return (
                            <div key={index}>
                                { violation.editMode ?
                                    <div  className="edit-violaton">
                                        <div className="flex-row edit-close-container">
                                            <span className="flex-col edit-close "><i className="fa fa-times" onClick={ e => this.handleToggleEditMode(e, violation) }></i></span>
                                        </div>
                                        <ViolationEntry riskSurvey={this.state.riskSurvey} violation={violation} handleSave={this.handleSave} ></ViolationEntry>
                                    </div>
                                :
                                    <div className="flex-row section violation">
                                        <div className="flex-col resolved-date-col">
                                            {moment.utc(violation.dates[0].date).format('MM-DD-YYYY')}
                                        </div>
                                        <div className="flex-col violation-col">
                                            {violation.location}: {violation.moduleNumber}{violation.referenceNumber} {violation.moduleName} - <span className="violation-questionText" dangerouslySetInnerHTML={ { __html: violation.questionText } } ></span>  
                                        </div>
                                        <div className="flex-col notes-col">
                                            { violation.notes ?
                                                <span className="note">Notes: {violation.notes}</span>
                                            : null }
                                        </div>
                                        <div className="flex-col action-col">
                                            <div className="flex-row action-row">
                                                <span className="flex-col"><i className="fa fa-edit" onClick={ e => this.handleToggleEditMode(e, violation) }></i></span>
                                                <span className="flex-col"><i className="fa fa-trash" onClick={ e => this.handleDelete(e, violation.riskSurveyModuleQuestionAnswerId) }></i></span>
                                            </div>
                                        </div>
                                    </div>
                                }   
                            </div>

                        )
                    })}
                </Paper> 
                : null }
                </div>            
            : null }
        </div>
    )
  }

  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }

  async handleSave(violation){
    try {
        let answer = {  riskSurveyId: this.state.riskSurvey.riskSurveyId, ...violation }
        await this.riskSurveyService.saveModuleQuestionAnswer(answer)
        await this.getRiskSurvey(this.context.riskSurvey.riskSurveyId)        
        this.context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Saving Answer')
    }
  }

  async handleDelete(event, answerId){
    if (window.confirm('Are you sure you want to delete?')) {
        try {
            event.preventDefault()        
            await this.riskSurveyService.deleteModuleQuestionAnswer(answerId)
            await this.getRiskSurvey(this.context.riskSurvey.riskSurveyId)
            this.context.toastSuccess('Saved')
        }
        catch(err){
        console.log(err)
        this.context.toastError('Saving Answer')
        }
    }
  }

  handleToggleEditMode(event, violation){
      violation.editMode = !violation.editMode
      this.setState({ violations: this.state.violations})
  }

  async handleResolved(event){
    try {
        event.preventDefault()
        let resolvedViolations = this.state.openViolations.filter( v => v.resolve )
        resolvedViolations.forEach( v => {
            v.answer = 'Yes'
            v.dates = [{date: this.state.resolvedDate}]
        })
        //save violations one by one
        await Promise.all(resolvedViolations.map( v => this.riskSurveyService.saveModuleQuestionAnswer(v)  ))
        await this.getRiskSurvey(this.context.riskSurvey.riskSurveyId)
        this.context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Saving Answer')
    }
  }

}

export default Module
