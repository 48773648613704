import React from 'react';
import AppContext from '../context/app'
import {TextField } from '@mui/material'


class TextAnswer extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
  }

  componentWillMount () {
    this.props.question.answers[0] = this.props.question.answers[0] || {}
  }

  handleAnswerChange (event) {
    let question = this.props.question
    let questionAnswer = { questionId: question.questionId,  answer: event.target.value }
    question.answers = [questionAnswer]

    //push state up to module level
    this.props.handleAnswerChange(question)
  }

  render () {
    return (
        <div>
            <div className="question-text">
                Q{this.props.question.index+1}: {this.props.question.text}
            </div>
            <div className="section">
              <TextField 
                type={this.props.type || 'text'} 
                label={this.props.question.config.notesLabel} 
                inputProps={{ maxLength: 4000}} 
                multiline rows={3}
                name='answer' 
                value={this.props.question.answers[0].answer } 
                required 
                disabled={this.props.disabled} 
                onChange={this.handleAnswerChange} />
            </div>
        </div>
    )
  }

}

export default TextAnswer
