import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../context/app'
import {TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material'


export default function RadioAnswer(props) {
  const context = useContext(AppContext)

  const [isEditableOther, setIsEditableOther] = useState(false)

  useEffect( async () => {
    props.question.answers[0] = props.question.answers[0] || {}
    //make other editable if they selected that
    if (props.question.answers[0]?.answer && !props.question.answerChoices.some( ac => props.question.answers[0]?.answer === ac.text))
      setIsEditableOther(true)
  },[])


  const handleAnswerChange = (answerChoice) => {
    if (answerChoice.isEditableOther && isEditableOther) 
      return

    let question = props.question
    let questionAnswer = { questionId: question.questionId,  answer: answerChoice.text }
    question.answers = [questionAnswer]
    if (answerChoice.isEditableOther) {   
      props.question.answers[0].answer = ''
    }
    setIsEditableOther(answerChoice.isEditableOther)

    //push state up to module level
    props.handleAnswerChange(question)
  }

  const handleOtherChange = (event) => {
    let question = props.question
    let questionAnswer = { questionId: question.questionId,  answer: event.target.value }
    question.answers = [questionAnswer]

    //push state up to module level
    props.handleAnswerChange(question)
  }

  const handleCommentChange = (event) => {
    let question = props.question
    question.answers[0].notes = event.target.value

    //push state up to module level
    props.handleAnswerChange(question)
  }


  return (
      <div>
          <div className="question-text">
              Q{props.question.index+1}: {props.question.text}
          </div>
          <div>
            <RadioGroup name={`answer_${props.question.questionId}`} required >
              { props.question.answerChoices.map( (answerChoice, index) => {                
                    return (
                      <div key={index} className="flex-row">
                        <div className="flex-col">
                          <FormControlLabel 
                            value={answerChoice.text || ''} 
                            label={`${answerChoice.text}${answerChoice.subText ? ` ${answerChoice.subText}` : ''}`}  
                            control={  
                                  <Radio 
                                    checked={!!((props.question.answers[0]?.answer && props.question.answers[0].answer === answerChoice.text) || (answerChoice.isEditableOther && isEditableOther))} 
                                    required disabled={props.disabled}
                                    onChange={ () => handleAnswerChange(answerChoice)} 
                                  />
                            }     
                          />
                        </div>
                        <div className="flex-col">
                          { answerChoice.isEditableOther && isEditableOther ?
                            <div className="other-text">
                              <TextField type="text" required 
                              inputProps={{maxLength: 2000 }} value={props.question.answers[0]?.answer || ''} onChange={handleOtherChange} />
                            </div>
                          : null }
                        </div>  
                      </div>
                    )
                  })
              }
            </RadioGroup>
          </div>

          <div className="section">
            <TextField type="text" multiline rows={3} maxLength="300" name='notes' value={props.question.answers[0]?.notes || ''} label={props.question.config.notesLabel} disabled={props.disabled} onChange={handleCommentChange} />
          </div>
      </div>
  )
}

