import React from 'react';
import AppContext from '../context/app'
import {Checkbox} from '@mui/material'

class MultipleCheckBoxAnswers extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
  }

  componentWillMount () {
    this.props.question.answers = this.props.question.answers || []
  }

  handleAnswerChange (event) {
    let question = this.props.question
    let answers = question.answers    

    let questionAnswer = { questionId: question.questionId,  answer: event.target.value }

    let isChecked = event.target.checked
    if (isChecked)
        answers.push(questionAnswer)
    else {
        let answerIndex = answers.findIndex( a => a.answer === questionAnswer.answer)
        if (answerIndex > -1)
            answers.splice(answerIndex,1)
    }
    
    question.answers = answers

    //push state up to module level
    this.props.handleAnswerChange(question)
  }

  render () {
    return (
        <div>
            <div className="question-text">
                Q{this.props.question.index+1}: {this.props.question.text}:
            </div>
            
            { this.props.answerChoices.map( (answerChoice) => {
                return (
                <div key={answerChoice}>
                    <span className="question-answer">
                      <Checkbox
                        checked={this.props.question.answers.find( a => a.answer === answerChoice) } 
                        disabled={this.props.disabled}
                        onChange={this.handleAnswerChange}
                        value={answerChoice} 
                      />
                      {answerChoice} 
                    </span>
                </div>
                )
                }) 
            }
        </div>
    )
  }

}


export default MultipleCheckBoxAnswers
