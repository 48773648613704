import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";

import { createBrowserHistory } from 'history';

import userService from '../services/user';

import AppError from '../components/AppError'
import Message from '../components/Message'
import Header from '../components/Header';
import Sidenav from '../components/Sidenav';

import RiskSurveyList from './RiskSurvey/List'
import RiskSurveyDetail from './RiskSurvey/Detail'
import RiskSurveyModule from './RiskSurvey/Module'
import RiskSurveyTemplate from './RiskSurvey/Template'
import RiskSurveyViolation from './RiskSurvey/Violation'
import RiskSurveyCreate from './RiskSurvey/Create'
import RiskSurveyReport from './RiskSurvey/Report'

import { withCookies } from 'react-cookie';
import ga from 'react-ga';
import AppContext from '../context/app'

import '../css/app.css'

const history = createBrowserHistory();

class App extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentDidMount () {
    // Listen for changes to the current location 
    history.listen(location => {
      //act like you changed pages by going to top
      window.scroll(0,0)

      //Log in Analytics
      ga.set({ page: location.pathname });
      ga.pageview(location.pathname);

      //security
      if (!this.props.cookies.get('clps'))
        window.location = 'https://login.clpsconsultants.com/'
    })

    //Default page
    if (!this.props.cookies.get('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    else {
      //load up data and goto default page
      new userService().get()
      .then( (user) => {
        if (!user){
          window.location = 'https://login.clpsconsultants.com/logout'
          return
        }
          
        console.log('logged in as: ', user.userId)
        ga.set({ user: user.userId });

        this.context.updateUser(user)
        this.setState({loading: false})
      })
      .catch( (err) => {        
        console.log(err);
        if (!process.env.REACT_APP_NO_RESET_ON_ERROR)
          window.location = 'https://login.clpsconsultants.com/'
      })
    }
}


  render () {
    const showSideNav = this.context.user && this.context.riskSurveyId
    let mainClass= showSideNav ? 'flex-col main-container' : ' flex-col main-container no-sidebar'

    return (
      <Router>
        { !this.state.loading ? 
        <div>
          <Message context={this.context}></Message>
          <Header context={this.context} />    
          <section className="flex-row content">
          <div className="flex-col content">
          {
              (showSideNav) ?
                <section className="flex-col sidebar-container">
                  <Sidenav context={this.context} />
                </section>
              :
                null
            }
            <div className="flex-row">
              <section className={mainClass}>
                <AppError>
                  <Switch>
                    <Route path="home" component={RiskSurveyList}/>
                    <Route path="/riskSurvey/list" component={RiskSurveyList}/>
                    <Route path="/riskSurvey/create"component={RiskSurveyCreate}/>
                    <Route path="/riskSurvey/copy/:copyFromRiskSurveyId"component={RiskSurveyCreate}/>
                    <Route path="/riskSurvey/edit/:riskSurveyId"component={RiskSurveyCreate}/>
                    <Route path="/riskSurvey/detail/:riskSurveyId" component={RiskSurveyDetail}/>
                    <Route path="/riskSurvey/module/:riskSurveyId/:moduleId" component={RiskSurveyModule}/>
                    <Route path="/riskSurvey/template/:riskSurveyId/:moduleId" component={RiskSurveyTemplate}/>
                    <Route path="/riskSurvey/violation/:riskSurveyId" component={RiskSurveyViolation}/>
                    <Route path="/riskSurvey/report/:riskSurveyId" component={RiskSurveyReport}/>                
                    <Redirect path="/" to="/riskSurvey/list" />
                  </Switch>
                </AppError>
                </section>
            </div>
          </div>
          </section>
      </div>
        : null }
      </Router>
    )
  }
}

export default withCookies(App)