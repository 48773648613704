import React from 'react';
import AppContext from '../context/app'
import {TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import moment from 'moment'


class YesNo extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  componentWillMount () {
    this.props.question.answers[0] = this.props.question.answers[0] || {}
  }

  handleAnswerChange (event) {
    let question = this.props.question
    let questionAnswer = { questionId: question.questionId,  answer: event.target.value }
    if (questionAnswer.answer === 'No')
      questionAnswer.notes = question.moduleQuestionDefaultRecommendation

    question.answers = [questionAnswer]

    if (question.calendarAvailable && !question.answers[0].dates)
      question.answers[0].dates = [{ questionId: question.questionId, date: moment().format('YYYY-MM-DD') }]

    //push state up to module level
    this.props.handleAnswerChange(question)
  }

  handleCommentChange (event) {
    let question = this.props.question
    question.answers[0].notes = event.target.value

    //push state up to module level
    this.props.handleAnswerChange(question)
  }


  render () {
    return (
        <div>
            <div className="question-text">
                Q{this.props.question.index+1}: {this.props.question.text}
            </div>
            <div>
              <RadioGroup name={`answer_${this.props.question.questionId}`} required onChange={this.handleAnswerChange}>
                <FormControlLabel value="Yes" control={<Radio checked={this.props.question.answers[0].answer === 'Yes'} required disabled={this.props.disabled} />} label="Yes" />
                <FormControlLabel value="No" control={<Radio checked={this.props.question.answers[0].answer === 'No'} required disabled={this.props.disabled} />} label="No" />

                { this.props.question.NAAvailable ?
                  <FormControlLabel value="NA" control={<Radio checked={this.props.question.answers[0].answer === 'NA'} required disabled={this.props.disabled} />} label="NA" />
                : null }
              </RadioGroup>
            </div>

            <div>
              <TextField type="text" maxLength="2000" inputProps={{ maxLength: 4000 }} name='notes' value={this.props.question.answers[0].notes || ''} multiline label={this.props.question.config.notesLabel} required={!!(this.props.question.answers[0].answer === 'No' && this.props.question.moduleQuestionDefaultRecommendation) } disabled={this.props.disabled} onChange={this.handleCommentChange} />
            </div>
        </div>
    )
  }

}

export default YesNo
