import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment'
import AppContext from '../context/app'
import { TextField, MenuItem } from '@mui/material'
import OtherDropDown from './OtherDropDown';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers';


export default function AdditionalData(props) {
  const context = useContext(AppContext)

  useEffect( async () => {
    if (!props.question.data) { 
      props.question.data = [{}]
      //default dateTimes to now
      props.question.config.additionalData.data.forEach( (additionalDataItem, index) => {
        if ( additionalDataItem.type === 'dateTime')
          props.question.data[additionalDataItem.dataProperty] = moment().format()
      })

      onChange()
    }
  },[])


  const onChange = () => {
    // save on the data property
    props.onChange(props.question)
  }

  if (!props.question.data)
    return null;

  return (
      <div className="additionalData">
      { props.question.data.map( (questionData, questionDataIndex) => {
        return (
          <div className="section-block" key={questionDataIndex}>
            { props.question.config.additionalData.data.map( (additionalDataItem, addIndex) => {
              return (
                  <div key={addIndex} className="section flex-row">
                      { additionalDataItem.type === 'OtherDropDown' ? 
                          <OtherDropDown label={additionalDataItem.label} value={questionData[additionalDataItem.dataProperty] || ''} required onChange={(e) => { questionData[additionalDataItem.dataProperty] = e.target.value; onChange()}} >
                            { additionalDataItem.options.map( (option, optionIndex) => {
                              return (
                                <MenuItem key={optionIndex} value={option}>{option}</MenuItem>
                              )
                            })}
                        </OtherDropDown>
                      : null }

                      { additionalDataItem.type === 'dateTime' ? 
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label={additionalDataItem.label} value={moment(questionData[additionalDataItem.dataProperty])} required onChange={(e) => { if (!e) { return }; if (!e.isValid()) { questionData[additionalDataItem.dataProperty] === null; };  questionData[additionalDataItem.dataProperty] = e.format(); onChange() }}/>
                          </LocalizationProvider>
                      : null }
                      
                      { additionalDataItem.type === 'text' ? 
                          <TextField label={additionalDataItem.label} type="text" maxLength="100" value={questionData[additionalDataItem.dataProperty] || ''} required onChange={(e) => { questionData[additionalDataItem.dataProperty] = e.target.value; onChange()}}/>
                      : null }
                  </div>
              )
            })}
          </div>
        )
        })}

      { props.question.config.additionalData.addMultiple ?
      <div className="add-another section">
          <span className='addAnotherOther' onClick={() => { props.question.data.push({}); onChange() }}><i className="fa fa-plus"> Add Another</i></span>
      </div>
      : null }

      </div>      

  )
}

