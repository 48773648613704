import React from 'react'
import AppContext from '../context/app'


class Loader extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
     <div id="loader">
        <div className="load-icon">
          <i className="fa fa-circle-notch fa-spin"></i>
         </div>
     </div>  
    )
  }
}

export default Loader;
