import React from 'react'
import AppContext from './app'

class AppContainer extends React.Component {
    constructor(props) {
        super(props);
        
        this.updateUser =  (user) => {
            this.setState( { user })
        }

        this.updateRiskSurveyId =  (riskSurveyId) => {
            this.setState( { riskSurveyId })
        }

        this.updateUserModuleComplete = (moduleId) => {
            let user = this.state.user
            user.completedModules.push({ moduleId })
            this.setState( { user })
        }

        this.updateRiskSurvey = (riskSurvey) => {
            this.setState({riskSurvey})
        }


        this.updateActiveModule =  (activeModule) => {
            this.setState( { activeModule })
        }

        this.toastSuccess = (message) =>{
            let toast = { type: 'success', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )
        }

        this.toastError = (message) =>{
            let toast = { type: 'error', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )  
        }

        this.removeToast = ()=>{
            this.setState( { toast: null })
        }
        
        this.state = {
            updateUser: this.updateUser,
            updateRiskSurveyId: this.updateRiskSurveyId,
            updateActiveModule: this.updateActiveModule,
            updateRiskSurvey: this.updateRiskSurvey,
            updateUserModuleComplete: this.updateUserModuleComplete,
            toastSuccess: this.toastSuccess,
            toastError: this.toastError,
            removeToast: this.removeToast
        }
    }

    render () {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}
export default AppContainer