import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import riskSurveyService from '../../services/riskSurvey';

import FileAnswer from '../../components/FileAnswer'
import File from '../../components/File'
import AppContext from '../../context/app'
import { styled } from '@mui/material/styles';
import { Paper, Button, Icon, Checkbox, MenuItem, TextField, FormControlLabel, FormGroup} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import * as Sentry from '@sentry/browser';
import HelpToolTip from '../../components/HelpToolTip';
import mustache from 'mustache'

export default function Custom(props) {
  const context = useContext(AppContext)
  const { riskSurveyId, moduleId } = useParams();

  const [riskSurvey, setRiskSurvey] = useState()
  const [module, setModule] = useState()
  const [template, setTemplate] = useState()

  const [templateData, setTemplateData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { riskSurveyId: riskSurveyId, moduleId, data: {} }
  );

  const renderMustacheRecursively = (item) => {
    if (item.mustache)
      item.rendered = mustache.render(item.mustache, { ...riskSurvey, ...templateData.data });
    
    if (item.bulletgroup)
      item.bulletgroup.forEach(bulletGroupItem => renderMustacheRecursively(bulletGroupItem));
  };
  
  let runMustacheUpdate = () => {
    template.forEach(templateItem => renderMustacheRecursively(templateItem));
    setTemplate([...template]);
  };

  useEffect( async () => {
    window.scroll(0,0)

    try {
      const riskSurvey = await new riskSurveyService().get(riskSurveyId)
      setRiskSurvey(riskSurvey)
      let module = riskSurvey.modules.find( (module) => { return module.moduleId === +moduleId })
      setModule(module)
      if (module.data)
        templateData.data = module.data 

      const loadedTemplate = await new riskSurveyService().getTemplate(riskSurvey.institutionId, riskSurvey.riskSurveyTypeId, moduleId)
      setTemplate(loadedTemplate)

      const setDefaultProperties = (templateItem) => {
         //Default date fields to today
         if (templateItem.input === 'date' && !templateData.data[templateItem.property])
          templateData.data[templateItem.property] = moment(); 
        
        //set Default Text
        if (templateItem.input === 'text' && templateItem.defaultText && !templateData.data[templateItem.property])
          templateData.data[templateItem.property] = templateItem.defaultText

        if (templateItem.bulletgroup)
          templateItem.bulletgroup.forEach(bulletGroupItem => setDefaultProperties(bulletGroupItem));
      };

      loadedTemplate.forEach( templateItem =>  { 
        setDefaultProperties(templateItem)
      })
      setTemplateData(templateData) 
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[moduleId])

  useEffect( async () => {
    if (template) {
      runMustacheUpdate()
    }
  },[templateData])

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      await new riskSurveyService().saveModuleData(templateData)
      context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Error Saving')
    }
  }

  const handleDelete = async (event) => {
    try {
      event.preventDefault();
      if (!window.confirm('Are you sure you would like to reset the answers?'))
        return
      await new riskSurveyService().deleteModuleQuestionAnswers(+riskSurveyId,+moduleId)
      context.toastSuccess('Reset')
      props.history.push({pathname: `/riskSurvey/detail/${riskSurveyId}` });
    }
    catch(err){
      console.log(err)
      this.context.toastError('Resetting')
    }
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const renderTemplateItem = (templateItem, index) =>  {
    return(
      <span key={index}>
        { templateItem.text ? <span className={`vertical-center ${templateItem.class ?? ''}`} dangerouslySetInnerHTML={{__html: templateItem.text}}></span> : null }

        { templateItem.mustache ? <span className={`vertical-center ${templateItem.class ?? ''}`} dangerouslySetInnerHTML={{__html: templateItem.rendered}}></span> : null }

        { !templateItem.collapsableGroup && templateItem.headline ? <span dangerouslySetInnerHTML={{__html: templateItem.headline}}></span> : null }

        { templateItem.input === 'text' ? 
        <span className={ `textInput-block ${templateItem.block ? 'template-block': ''}`}> 
          <span className="flex-row">
            <span className="flex-col textInput">
              <TextField size="small"  multiline={true} minRows={templateItem.multiline ? 3 : 1} className={`${!templateItem.block ? 'small-input' : ''} ${templateData.data[templateItem.property]?.length > 40 || templateItem.defaultText?.length > 40 ? 'small-input-expanded': ''} ${templateItem.multiline ? 'textbox-input' : ''}`} label={templateItem.name} value={templateData.data[templateItem.property] || '' } onChange={ e => { templateData.data[templateItem.property] = e.target.value; setTemplateData(templateData) } } ></TextField>
            </span>
            {templateItem.helpText ? 
              <span className="flex-col help"><HelpToolTip title={<div>{templateItem.helpText}</div> }></HelpToolTip></span>
            : null}
          </span>
        </span>
         
        : null }

        { templateItem.input === 'date' ? 
          <span className={ templateItem.block ? 'template-block': '' }>                            
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker size="small" className={`${templateItem.class || 'small-input'}`} renderInput={(props) => <TextField {...props} />} label={templateItem.name} value={templateData.data[templateItem.property] || moment()} onChange={(e) => {if (!e) { return }; templateData.data[templateItem.property] = e; setTemplateData(templateData)}}/>
                </LocalizationProvider>
          </span> 
        : null }

        { templateItem.input === 'multiCheckbox' ? 
           <FormGroup className={!!templateItem.row ? 'row' : ''} aria-label="position" row={!!templateItem.row}>
            { templateItem.choices.map( (choice, index) => {
                  return (
                    <FormControlLabel key={index}
                        control={<Checkbox />}
                        label={choice} 
                        labelPlacement="right"
                        checked={templateData.data[templateItem.property]?.find( a => a === choice) } 
                        onChange={ e => { if (!templateData.data[templateItem.property]) { templateData.data[templateItem.property] = [] }; if (e.target.checked) { templateData.data[templateItem.property].push(choice) } else { templateData.data[templateItem.property].pop(choice) }; setTemplateData(templateData) } }

                    />
                  )
                  }) 
              }
             </FormGroup>
        : null }

        { templateItem.input === 'dropdown' ? 
          <span>
            <TextField select className="dropdown" label={templateItem.name || ''}  value={templateData.data[templateItem.property] || ''} onChange={ e => { templateData.data[templateItem.property] = e.target.value; setTemplateData(templateData)} }>                    
            { templateItem.choices.map( (choice, index) => {
                return(
                  <MenuItem key={index} value={choice}>
                   {choice}&nbsp;
                  </MenuItem>
                )
              }) 
            }
            </TextField>                       
          </span> 
        : null }

        { templateItem.input === 'imageUpload' ? 
          <span>
            <FileAnswer mode={templateItem.fileUploadMode} question={{ questionId: templateItem.questionId}} riskSurveyId={riskSurvey.riskSurveyId} disabled={!riskSurvey.isOpen}></FileAnswer>
          </span> 
        : null }

        { templateItem.group ?
          <span>
            { templateItem.group.map( (templateGroupItem, index) => {
              return(
                renderTemplateItem(templateGroupItem, index)
              )
            })}
          </span>
        : null }

        { templateItem.bulletgroup ?
          <li>
            { templateItem.bulletgroup.map( (templateGroupItem, index) => {
              return( renderTemplateItem(templateGroupItem, index))
            })}
          </li>
        : null }

        { templateItem.collapsableGroup ?
          <Paper>
            <span className="flex-row">
              <span className="flex-col" dangerouslySetInnerHTML={{__html: templateItem.headline}}></span>
              <span className="flex-col">
                  <ExpandMore
                  expand={templateData[templateItem.property]}
                  onClick={ (e) => { templateData[templateItem.property] = !templateData[templateItem.property]; setTemplateData({...templateData}) }}
                  aria-expanded={templateData[templateItem.property]}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </span>
            </span>
            <Collapse in={templateData[templateItem.property]}>
              { templateItem.collapsableGroup.map( (collapsableGroupItem, index) => {
                return( renderTemplateItem(collapsableGroupItem, index))
              })}
            </Collapse>
          </Paper>
        : null }



      </span>
    )
  }

  const isDeletable = riskSurvey?.isOpen && templateData

  return (
    <div id='riskSurveyCustom'>
          { module?.fileUpload ?
            <File riskSurveyId={riskSurveyId} moduleId={moduleId} ></File>
          : null }
          <form onSubmit={handleSave}>
            { template ?
              <Paper className="paper">
              { template.map( (templateItem, index) => {
                return (renderTemplateItem(templateItem, index))
              })}     
              </Paper>
            : null }

            <div className="section template-actions flex-row">
            <div className="flex-col">
                <Button type="submit" variant="outlined"><Icon className="fa fa-save"/>Save</Button>
            </div>

            { isDeletable ?
              <div className="flex-col">
                <Button variant="outlined" onClick={handleDelete}><Icon className="fa fa-trash"/>Reset</Button>
              </div>
            : null }
            </div>
          </form>
      </div>
  )
}