import React, { useState, useEffect, useContext } from 'react';
import riskSurveyService from '../services/riskSurvey';
import { Camera as CameraIcon } from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone'
import FileViewer from './FileViewer'
import AppContext from '../context/app'

export default function FileAnswer(props) {
    const context = useContext(AppContext)
    const [assetNames, setAssetNames] = useState([])
    const [files, setFiles] = useState([])
    const [fileViewerOpen, setFileViewerOpen] = useState(false)
    const [fileViewerAssetIndex, setFileViewerAssetIndex ] = useState()


    const bindAssets = async () => {
      let assetNames = await new riskSurveyService().getModuleQuestionAssetNames(+props.riskSurveyId, +props.question.questionId)
      const imageExt = ['.png','.gif','.jpg','.jpeg']
      let endsWithAny = (suffixes, string) => {
        return suffixes.some(function (suffix) {
            return string.toLowerCase().endsWith(suffix);
        });
      }
      assetNames.forEach( (a) => {
        a.isImage = endsWithAny(imageExt, a.fileName)
      })
      setAssetNames(assetNames)
    }

    useEffect( async () => {
        bindAssets()
      },[])
    
    
    const handleUploadAsset = async (files) => {
      if (!files.length)
        return

      try {
        async function uploadFiles() {
          for (const file of files) {
            let data = new FormData()
            data.append('file', file.file)
            await new riskSurveyService().moduleQuestionUploadFile(+props.riskSurveyId, +props.question.questionId, data)
          }
        }

        await uploadFiles.bind(this)()

        //reload the images
        bindAssets()
        setFiles([])
        context.toastSuccess('File uploaded')
      }
      catch(err){
        console.log(err)
        context.toastError('File could not be uploaded')
        setFiles([])
      }
    }
    
      const handleDeleteAsset = async (assetId) => {
        try {
          await new riskSurveyService().deleteModuleQuestionAsset(+assetId)
          bindAssets()
        }
        catch(err){
          console.log(err)
          context.toastError('Deleting file')
        }
    
      }
    
    return (
        <>
        <FileViewer open={fileViewerOpen} onClose={e => setFileViewerOpen(false)} files={assetNames} index={fileViewerAssetIndex} riskSurveyId={props.riskSurveyId} questionId={props.question.questionId} ></FileViewer>
        <div id='riskSurveyModule'>
                <div>
                    { props.question.questionType === 'file' && props.question.text ?
                      <div className="question-text">
                          Q{props.question.index+1}: {props.question.text}
                      </div>
                    : null }

                    <div className="flex-row section">
                      { assetNames.map( (fileName, index) => {
                        return(
                          <div className="flex-col file-list uploaded-file-container" key={index}>
                            { fileName.isImage ?
                              <img  
                                src={`/api/riskSurvey/asset/getModuleQuestionAsset?assetId=${fileName.riskSurveyModuleQuestionAssetId}`}
                                className="uploaded-file"
                                data-fileName={fileName.fileName}
                                alt={ fileName.notes || fileName.fileName  }
                                title={fileName.notes || fileName.fileName }
                                onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                              />
                            :
                              <span 
                                className="fa fa-file uploaded-file"
                                data-fileName={fileName.fileName}
                                alt={ fileName.notes || fileName.fileName  }
                                title={fileName.notes || fileName.fileName }
                                onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                              />
                            }

                            <span>
                                <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteAsset(fileName.riskSurveyModuleQuestionAssetId) }}></i>
                            </span>
                          <div className="uploaded-file-caption">
                            {fileName.notes ||  fileName.fileName.substring(9,fileName.fileName.length)}
                          </div>
                          </div>
                        )
                        }) 
                      }
                    </div>

                    <div className="dropzonearea">                  
                      <DropzoneAreaBase
                        maxFileSize={30000000}
                        filesLimit={30}
                        onAdd={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadAsset(newFiles)}}
                        acceptedFiles={ props.mode === 'image' ? ['image/*'] : null }
                        fileObjects={files}
                        Icon={ props.mode === 'image' ? CameraIcon : null}
                        dropzoneText={props.mode === 'image' ? "Add a Picture" : "Drag and drop a file here or click"}
                      />
                    </div> 
                </div>
              </div>
        </>
      )
}