import React from 'react';
import AppContext from '../context/app'
import { Paper, TextField, Button, Icon, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import moment from 'moment'

class ViolationEntry extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { questions: [] }
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this)
    this.handleToggleEditMode = this.handleToggleEditMode.bind(this)
  }

  componentDidMount () {
    this.setState( {...this.props.violation} )

    if (this.props.violation){
        let module = this.props.riskSurvey.modules.find( m => m.moduleId === this.props.violation.moduleId)
        this.setState({module, questions: module.questions})
    }
  }

  handleSave (event) {
    event.preventDefault()
    this.state.answer = !this.state.dates || !this.state.dates.length ? 'No' : 'Yes'
    this.props.handleSave(this.state)
    this.setState({location: '', moduleId: '', questionId: '', notes: ''})
  }

  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }

  handleSectionUpdate(event) {
    let module = this.props.riskSurvey.modules.find( m => m.moduleId === event.target.value)
    this.setState({module, questions: module.questions})
  }

  handleToggleEditMode(event, violation){
    this.setState({ editMode: !violation.editMode})
  }

  render () {
    return (
        <form onSubmit={this.handleSave} method='post' encType="multipart/form-data">
            <Paper>
                <div className="flex-row row">
                    { this.state.resolved ?
                        <div className="flex-col section">
                            <TextField label="Date Corrected" type="date" onChange={(e) => { this.state.dates = e.target.value.length ? [{ date: moment.utc(e.target.value).format('YYYY-MM-DD')}] : []; this.setState({dates: this.state.dates}) }} value={this.state.dates.length ?  moment.utc(this.state.dates[0].date).format('YYYY-MM-DD') : ' ' }/>
                        </div>
                    : null }

                    <div className="flex-col location section">
                        <TextField className="location-input" required type="text" maxLength="100" name='location' value={this.state.location || ''} label="Location" onChange={this.handleChange} />
                    </div>
                    <div className="flex-col col section">
                        <FormControl required className="section">
                            <InputLabel>Code Category</InputLabel>
                            <Select label="Section" required name="moduleId" value={this.state.moduleId || ''} onChange={ (e) => { this.handleChange(e); this.handleSectionUpdate(e) }}  >
                                <MenuItem  value="">Code Category</MenuItem>
                                { this.props.riskSurvey.modules.map( (module, index) => {
                                    return (
                                        <MenuItem key={index} value={module.moduleId}><span dangerouslySetInnerHTML={ { __html: `${module.moduleNumber} ${module.name}` } }></span></MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex-col col section">
                        <FormControl required>
                            <InputLabel>Section</InputLabel>
                            <Select disabled={!this.state.questions.length} label="Violation" required name="questionId" value={this.state.questionId || ''} onChange={this.handleChange}  >
                                <MenuItem  value="">Section</MenuItem>
                                { this.state.questions.map( (question, index) => {
                                    return (
                                        <MenuItem className="question-menu-item"  key={index} value={question.questionId}><span dangerouslySetInnerHTML={ { __html: `${this.state.module.moduleNumber}${question.referenceNumber} ${question.heading ? `${question.heading} ` : ''}${question.text}` } }></span></MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex-row row">
                        <div className="flex-col notes section">
                            <TextField className="notes-input" type="text" maxLength="500" name='notes' value={this.state.notes || ''} multiline label="Additional Notes" onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

                <div className="section">
                    <Button type="submit" variant="outlined" disabled={!this.state.location || !this.state.moduleId || !this.state.questionId }><Icon className="fa fa-save"/>Save</Button>
                </div>

            </Paper>
        </form>
    )
  }

}


export default ViolationEntry
