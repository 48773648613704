import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'
import * as Sentry from '@sentry/browser';
import riskSurveyService from '../../services/riskSurvey';
import AppContext from '../../context/app'

import { Paper, Fab, Icon, Button, TextField, MenuItem, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

const iconSurveyTypeMap = {
  "fireSurvey": 'fa-fire',
  "riskSurvey": 'fa-clipboard-check',
  'riskSurveyBusiness': 'fa-clipboard-check',
  'riskSurveyWorship': 'fa-clipboard-check',
  'riskSurveyTargeted': 'fa-user-shield',
  'riskSurveyResidential': 'fa-building-shield',
  'riskSurveyProtection': 'fa-shield',
  'riskSurveyJewish': 'fa-clipboard-check'
}

export default function List(props) {
  const context = useContext(AppContext)

  const [allRiskSurveys, setAllRiskSurveys] = useState([])
  const [openRiskSurveys, setOpenRiskSurveys] = useState([])
  const [closedRiskSurveys, setClosedRiskSurveys] = useState([])
  const [displayArchive, setDisplayArchive] = useState()
  const [searchWord, setSearchWord] = useState()
  const [district, setDistrict] = useState()
  const [districts, setDistricts] = useState([])
  
  useEffect( async () => {
    window.scroll(0,0)
    context.updateRiskSurveyId(null)

    try {
      let riskSurveys = await new riskSurveyService().getAll()
      setAllRiskSurveys(riskSurveys)

      let openRiskSurveys = riskSurveys.filter( (riskSurvey) => { return riskSurvey.isOpen })
      let closedRiskSurveys = riskSurveys.filter( (riskSurvey) => { return !riskSurvey.isOpen })
      setOpenRiskSurveys(openRiskSurveys)
      setClosedRiskSurveys(closedRiskSurveys)
  
      //for filter
      let uniqueDistricts = [...new Set(riskSurveys.map(rs => rs.district))].sort((a,b) => a - b)
      uniqueDistricts = uniqueDistricts.filter( d => d && d.length)
      setDistricts(uniqueDistricts)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])


  useEffect( () => {
    let riskSurveys = allRiskSurveys
    if (district && district.length)
      riskSurveys = riskSurveys.filter( rs => !rs.district || rs.district.toLowerCase().includes(district.toLowerCase()) )

    //front end search
    if (searchWord && searchWord.length)
      riskSurveys = riskSurveys.filter( rs => `${rs.district} ${rs.name} ${rs.institutionName}`.toLowerCase().includes(searchWord.toLowerCase()) )

    let openRiskSurveys = riskSurveys.filter( (riskSurvey) => { return riskSurvey.isOpen })
    let closedRiskSurveys = riskSurveys.filter( (riskSurvey) => { return !riskSurvey.isOpen })
    setOpenRiskSurveys(openRiskSurveys)
    setClosedRiskSurveys(closedRiskSurveys) 
  },[searchWord, district])

  useEffect( async () => {
    
  },[district])

  const downloadManagedExcel = (event) => {
    new riskSurveyService().downloadManagedExcel()
  }

  return (
    <div id='riskSurveys'>
          { context.user ?
            <div>
              <div>
                <div className="flex-row">                  
                    <div className="create-risk-survey-button-holder">
                        <Link className='create-risk-survey-button' to={`/riskSurvey/create`}>
                          <Tooltip title="Add">
                            <Fab className="flex-col no-print" size="small" color="primary" aria-label="add">
                              <AddIcon /> 
                            </Fab>
                          </Tooltip>
                        </Link>
                    </div>
                  </div>

                  { allRiskSurveys.length ?
                    <Paper elevation={1} className="flex-row search-bar">
                          { districts.length ?
                            <div className="flex-col">
                                <FormControl className="search-field search-field-small">
                                    <TextField select label="District" value={district || ''} onChange={(e) => setDistrict(e.target.value)} >
                                      <MenuItem value="">All</MenuItem> 
                                      { districts.map( (districtItem, index) => {
                                        return(
                                          <MenuItem key={index} value={districtItem}>{districtItem}</MenuItem> 
                                          )
                                        })
                                      }                                
                                    </TextField>
                                </FormControl>
                            </div>
                          : null }
                          <div className="flex-col">
                            <div className="flex-row search-fields">
                              <div className="flex-col">
                                <TextField className="search-field" maxLength="60" type="text" label="Search" value={searchWord} required onChange={(e) => setSearchWord(e.target.value)} />
                              </div>
                            </div>
                          </div>
                    </Paper>
                  : null }

                  { openRiskSurveys.length ?
                    <div className="riskSurveys">
                      {
                          openRiskSurveys.map( (riskSurvey, index) => {
                              return (
                                <Link className="flex-row" to={`/riskSurvey/detail/${riskSurvey.riskSurveyId}`} key={index}>
                                  <Paper className="flex-row riskSurveyHeadline riskSurvey">
                                      <div className="flex-col riskSurveyInfo">
                                        <div className="flex-row">
                                          <div className="flex-col riskSurveyName">
                                            <i title={riskSurvey.typeName} className={`riskSurveyIcon fa ${iconSurveyTypeMap[riskSurvey.riskSurveyTypeSystem]}`}></i>
                                            { riskSurvey.district ?
                                            <span className="riskSurveyDistrict">{riskSurvey.district}:</span> 
                                            : null }
                                            <span className="" dangerouslySetInnerHTML={ { __html: riskSurvey.name} }></span>  
                                          </div>
                                        </div>
                                        <div className="riskSurveyInstitutionName flex-row">
                                          {riskSurvey.institutionName}
                                        </div>
                                        <div className="riskSurveyCreateDate flex-row">
                                              Created: {moment.utc(riskSurvey.timeStamp).local().format('MM/DD/YYYY')}
                                          </div>

                                      </div>

                                      <span className="flex-col">
                                        <Button variant="outlined">
                                          <Icon className="fa fa-edit" />Open
                                        </Button>
                                      </span>
                                  </Paper>
                                </Link>
                              );
                          })
                      }
                  </div>
                :
                  null
                }
              </div>

              { closedRiskSurveys.length ?
                  <div className="riskSurveys">
                    <h2>Archived</h2>
                    <span onClick={(e) => setDisplayArchive(!displayArchive)}>
                      { displayArchive ? 
                        <span><i className="fa fa-arrow-up"></i>Hide Archive</span> 
                      : 
                        <span><i className="fa fa-arrow-down"></i>Show Archive</span>
                      }
                    </span>
                    { displayArchive ?
                      <div>
                      {
                          closedRiskSurveys.map( (riskSurvey, index) => {
                              return (
                              <Link key={index} className="flex-row" to={`/riskSurvey/detail/${riskSurvey.riskSurveyId}`}>
                                <Paper className="flex-row riskSurvey" elevation={1}>
                                  <div className="flex-col riskSurveyInfo">
                                        <div className="flex-row">
                                          <div className="flex-col riskSurveyName">
                                            <i title={riskSurvey.typeName} className={`riskSurveyIcon fa ${iconSurveyTypeMap[riskSurvey.riskSurveyTypeSystem]}`}></i>
                                            { riskSurvey.district ?
                                            <span className="riskSurveyDistrict">{riskSurvey.district}:</span> 
                                            : null }
                                            <span className="" dangerouslySetInnerHTML={ { __html: riskSurvey.name} }></span>  
                                          </div>
                                        </div>
                                        <div className="riskSurveyInstitutionName flex-row">
                                          {riskSurvey.institutionName}
                                        </div>
                                        <div className="riskSurveyCreateDate flex-row">
                                              Created: {moment.utc(riskSurvey.timeStamp).local().format('MM/DD/YYYY')}
                                          </div>

                                      </div>

                                    <span className="flex-col">
                                      <Button variant="outlined">
                                        <Icon className="fa fa-edit" />Open
                                      </Button>
                                    </span>
                                </Paper>
                              </Link>
                              );
                          })
                      }
                      </div>
                    : null
                    }
                  </div>
                :
                  null
                }

          </div>
      : null }
    </div>
  )
}