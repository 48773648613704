import React from 'react';
import { createBrowserHistory } from 'history';
import _ from 'lodash'
import moment from 'moment'

import riskSurveyService from '../../services/riskSurvey'
import YesNo from '../../components/YesNo'
import MultipleCheckBoxAnswers from '../../components/MultipleCheckboxAnswers'
import TextAnswer from '../../components/TextAnswer'
import RadioAnswer from '../../components/RadioAnswer'
import MinSecAnswer from '../../components/MinSecAnswer'
import FileAnswer from '../../components/FileAnswer'
import AdditionalData from '../../components/AdditionalData'


import File from '../../components/File'
import AppContext from '../../context/app'

import { Paper, TextField, Button, Icon, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


class Module extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { riskSurvey: {}, questions: []};
    this.riskSurveyService = new riskSurveyService();
    this.handleUpdateQuestion = this.handleUpdateQuestion.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateAdd = this.handleDateAdd.bind(this)
    this.handleDateUpdate = this.handleDateUpdate.bind(this)
    this.handleDateRemove = this.handleDateRemove.bind(this)
    this.calendarAvailableFunctionMap = this.calendarAvailableFunctionMap.bind(this)
    this.history = createBrowserHistory();
  }

  calendarAvailableFunctionMap() {
    return {
      'Yes': (question) => question.answers.some(a => a.answer === 'Yes'),
      'ChangeYes': (question) => { 
        //if you have dates already, show
        if (question.answers.some(a => a.answer === 'Yes') && question.dates.filter( d => !d.default ).length)
          return true
        //match saved question vs new and only if changed to Yes
        let savedAnswers = this.state.savedQuestions.find(oq => oq.questionId === question.questionId).answers
        if (!savedAnswers.length || JSON.stringify(savedAnswers) === '{}')
          return false
        return savedAnswers.some(a => a.answer === 'No') && question.answers.some(a => a.answer === 'Yes')
      }
    }
  }

  bindPage(props){
    if (!this.context.riskSurvey) {
      window.location = '/'
      return;
    }

    //put risk survey in state
    this.setState({ riskSurvey: this.context.riskSurvey })

    //get module to answer
    let module = this.context.riskSurvey.modules.find( (module) => { return module.moduleId === +props.match.params.moduleId })
    let savedQuestions = _.cloneDeep(module.questions)
    let storeRecommendation = module.notes || {}
    module.questions.forEach( question => { 
      question.showDates = question.answers && question.answers.length && !!question.answers[0].dates && !!question.answers[0].dates.length
    })    
    this.setState({module, savedQuestions, questions: module.questions, recommendations: storeRecommendation.recommendation || '' })
   }

  componentWillReceiveProps(nextProps){
    if (this.props.match.params.moduleId !== nextProps.match.params.moduleId) {
      this.bindPage(nextProps)
    }
  }

  componentDidMount () {
    window.scroll(0,0)
    this.bindPage(this.props)
  }

  render () {
    const isSaveAble = this.state.riskSurvey.isOpen && (this.state.questions.length || this.state.module.notesOnly)

    return (
      <div id='riskSurveyModule'>
            { this.state.module ?
              <div>
                  <form onSubmit={this.handleSave} method='post' encType="multipart/form-data">
                    <div className="questions">
                      { this.state.questions.map( (question, index) => {
                            question.index = index
                            return (
                              <Paper key={index} className="section" elevation={1}>
                                <div className='rows question' key={question.questionId} >
                                    { question.questionType === 'YesNo' ? 
                                      <div>
                                        <YesNo question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion} ></YesNo>
                                        { question.config?.additionalData && question.answers.some( a => a.answer === 'Yes')  ?
                                          <AdditionalData question={question} disabled={!this.state.riskSurvey.isOpen} onChange={ (e) => { this.handleUpdateQuestion(question) }} ></AdditionalData>
                                        : null }
                                      </div>
                                    : null }

                                    { question.questionType === 'CheckBox' ? 
                                      <MultipleCheckBoxAnswers question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion} answerChoices={['Homicide','Violent Assault/Battery','Sexual Assault/Rape', 'Weapon Possession', 'Bias Incident/Hate Crime', 'Arson/Criminal Mischief', 'Burglary/Robbery']}></MultipleCheckBoxAnswers>
                                    : null }

                                    { question.questionType === 'text' ? 
                                      <TextAnswer question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion}></TextAnswer>
                                    : null }

                                    { question.questionType === 'number' ? 
                                      <TextAnswer type="number" question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion}></TextAnswer>
                                    : null }

                                    { question.questionType === 'radio' ? 
                                      <RadioAnswer question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion}></RadioAnswer>
                                    : null }

                                    { question.questionType === 'MinSec' ? 
                                      <MinSecAnswer question={question} disabled={!this.state.riskSurvey.isOpen} handleAnswerChange={this.handleUpdateQuestion}></MinSecAnswer>
                                    : null }

                                   { question.showDates ?
                                      <div className="date-entry">
                                        { question.answers[0].dates.map( (date, index) => {
                                          return (
                                            <div key={index} className="flex-row">
                                              <div className="flex-col date">
                                                <TextField type="date" label={question.calendarAvailable} onChange={(e)=> this.handleDateUpdate(e, question, index)} value={moment.utc(date.date).format('YYYY-MM-DD') || ''} required={!question.answers[0].dates.length}/>
                                               </div>
                                              <span className="flex-col date-action">
                                                <span className="flex-row">
                                                    { index === question.answers[0].dates.length-1 ?
                                                        <span className="flex-col add">
                                                            <Fab size="small" color="primary" aria-label="add" onClick={(e)=> this.handleDateAdd(e, question)}>
                                                                <AddIcon /> 
                                                            </Fab>
                                                        </span>
                                                    : null }

                                                    { index > 0 ?
                                                        <span className="flex-col remove">
                                                            <Fab size="small" aria-label="add" onClick={(e)=> this.handleDateRemove(e, question, index)}>
                                                                <RemoveIcon /> 
                                                            </Fab>
                                                        </span>
                                                    : null }
                                                </span>
                                              </span>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    : null}

                                    { question.config?.fileUpload ?
                                        <FileAnswer mode={question.config.fileUploadMode} question={question} riskSurveyId={this.state.riskSurvey.riskSurveyId} disabled={!this.state.riskSurvey.isOpen}></FileAnswer>
                                    : null }
                                </div>
                              </Paper>
                            )
                        }
                      )}
                    </div>

                    { this.state.questions.length || this.state.module.notesOnly ?
                      <div className="section">
                        <Paper>
                          <TextField className="recommendations" name='recommendations' maxLength="10000" multiline rows={5} label="Additional Notes" value={this.state.recommendations} onChange={this.handleChange} />
                        </Paper>
                      </div>
                    : null }

                    { isSaveAble ?
                      <div className="section">
                        <Button type="submit" variant="outlined" disabled={  !this.state.module.notesOnly && !this.state.recommendations.length && !this.state.questions.some(q => !q.answers || q.answers.length) }><Icon className="fa fa-save"/>Save</Button>
                      </div>
                    : null }
                  </form>
                
              { this.state.module.fileUpload ?
                  <File riskSurveyId={this.props.match.params.riskSurveyId} moduleId={this.props.match.params.moduleId} ></File>
              : null }
              </div>
              : null }
            </div>
    )
  }


  //set to state
  handleChange(event, key) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }

  handleDateUpdate(event, question, datesIndex) {
    //update question
    let questions = _.cloneDeep(this.state.questions)
    let questionToUpdate = questions.find( q => q.questionId === question.questionId )
    questionToUpdate.answers[0].dates[datesIndex] = { questionId: question.questionId, date: event.target.value }
    this.setState( { questions });    
  }

  handleDateAdd(event, question) {
    let questions = _.cloneDeep(this.state.questions)
    let questionToUpdate = questions.find( q => q.questionId === question.questionId )
    questionToUpdate.answers[0].dates.push({ questionId: question.questionId, date: moment.utc().local().format('YYYY-MM-DD') })
    this.setState( { questions });    
  }

  handleDateRemove(event, question, index) {
    //update question
    let questions = _.cloneDeep(this.state.questions)
    let questionToUpdate = questions.find( q => q.questionId === question.questionId )
    questionToUpdate.answers[0].dates.splice(index, 1);
    this.setState( { questions });    
  }


  handleUpdateQuestion(question) {
    let questions = _.cloneDeep(this.state.questions)
    let questionToUpdate = questions.find( q => q.questionId === question.questionId ) 
    questionToUpdate.answers = question.answers
    questionToUpdate.data = question.data
    questionToUpdate.notes = question.notes

    //decide to show dates
    questionToUpdate.showDates = question.calendarAvailable && this.calendarAvailableFunctionMap()[question.calendarAvailableWhen](questionToUpdate)

    //if autoAnswer question, mark others
    if (questionToUpdate.autoAnswer){
      questionToUpdate.autoAnswer.forEach( (autoAnswer) => { 
        let autoAnswerQuestion = questions.find( q => q.questionId === autoAnswer.questionId ) 
        if (questionToUpdate.answers.some( a => a.answer === 'No')) {
          autoAnswerQuestion.answers = [{ questionId: autoAnswer.questionId,  answer: autoAnswer.answer }]
          autoAnswerQuestion.autoAnswered = true
        }
        else {
          autoAnswerQuestion.autoAnswered = false
        }
      })
    }

    this.setState( { questions });

  }

  async handleSave(event){
    try {
      event.preventDefault();
      if (this.state.module.notesOnly)
        await this.riskSurveyService.saveModuleNote({ riskSurveyId: +this.props.match.params.riskSurveyId, moduleId: +this.props.match.params.moduleId, moduleNotes: this.state.recommendations })
      else { 
        let moduleAnswers = { riskSurveyId: +this.props.match.params.riskSurveyId, moduleId: +this.props.match.params.moduleId, questions: this.state.questions,  moduleNotes: this.state.recommendations  };  
        await this.riskSurveyService.saveModuleQuestionAnswers(moduleAnswers)
      }
      this.props.history.push({pathname: `/riskSurvey/detail/${this.props.match.params.riskSurveyId}` });
      this.context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Saving Risk Survey')
    }
  }
}

export default Module
