import React from 'react';
import PropTypes from "prop-types";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Icon, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

import riskSurveyService from '../services/riskSurvey';

File.propTypes = {
  assetNames: PropTypes.array
};

export default function File(props) {

  const handleDownloadFile = (assetId) => {
    if (props.moduleId)
      new riskSurveyService().downloadAsset(+assetId)
    else
      new riskSurveyService().getModuleQuestionAsset(+assetId)
  }

  const getImageSource = (file) => {
    let imageSource = `/api/riskSurvey/asset/getAsset?assetId=${file.riskSurveyModuleAssetId}`
    if (props.questionId)
      imageSource =  `/api/riskSurvey/asset/getModuleQuestionAsset?assetId=${file.riskSurveyModuleQuestionAssetId}`  
    return imageSource
  }

  return (

    <div className="fileviewer">
      <Dialog className="fileviewer-dialog" fullWidth={true} open={props.open} onClose={props.onClose} aria-labelledby="simple-dialog-title">
          { props.title ?
            <DialogTitle id="dialog-title">{props.title}</DialogTitle>
          : null }
          <Carousel autoPlay={false} index={props.index}>
            { props.files.map( (file, index) => {
              return(
                <div className="uploaded-file-container" key={index}>
                  { file.isImage ?
                    <img 
                      src={getImageSource(file)}
                      className="uploaded-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                      
                    />
                  :
                    <span 
                      className="fa fa-file uploaded-file uploaded-file-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                    />
                  }
                  <div className="uploaded-file-caption">
                    {file.notes ||  file.fileName}
                  </div>

                  <div className="download-button">
                    <Button variant="outlined" onClick={ (e) => handleDownloadFile( props.questionId ? file.riskSurveyModuleQuestionAssetId : file.riskSurveyModuleAssetId)}>
                      <Icon className="fa fa-download"/>Download
                    </Button>
                  </div>
                </div>
              )
              }) 
              }
          </Carousel>
      </Dialog> 
    </div>
  )
}